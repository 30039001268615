<template>
  <div>
    <vue-headful :title="pageheading" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area" class="annual-questionnaire-form">
        <div>
          <form
            @submit.prevent="validateBeforeSubmit"
            style="margin: 0 auto;"
          >
            <div
              v-if="!isViewOnly"
              id="buttongroup"
              style="
                padding-bottom: 20px;
                width: 100%;
                display: flex;
                justify-content: flex-end !important;
                gap: 0.5rem;
              "
            >
              <div class="" style="max-width: 60px">
                <button :disabled="!$hasPermissions(clientSession, ['ANNUAL_QUESTIONNAIRE'], 2)" type="submit" class="button is-accent has-text-white">
                  Save
                </button>
              </div>
              <div class="">
                <a
                  class="button is-light"
                  v-on:click="
                    resetForm();
                    $router.go(-1);
                  "
                  >Cancel</a
                >
              </div>
            </div>
            <div class="columns"  v-if="!isViewOnly">
              <div class="column" style="max-width: 50%">
                <div
                  class="block"
                  style="
                    padding: 0px 0px 7px 0px;
                    border-bottom: solid 1px lightgray;
                  "
                >
                  <span class="has-text-weight-bold"
                    >Questionnaire Properties</span
                  >
                </div>
                <div class="block">
                  <div class="flex-container">
                    <div class="flex-field">
                      <label class="label is-size-7">
                        Name
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>
                      <div class="field">
                        <input
                          type="text"
                          v-model="name"
                          style="max-width: 320px"
                        />
                      </div>
                    </div>
                    <div class="flex-field">
                      <label class="label is-size-7">
                        Due Date
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>
                      <kendo-datepicker
                        id="dueDate"
                        name="due date"
                        v-validate="'required'"
                        style="max-width: 150px"
                        v-model="input.dueDate"
                        :format="'yyyy-MM-dd'"
                      >
                      </kendo-datepicker>
                      <span
                        v-show="errors.has('due date')"
                        class="help is-danger"
                        >{{ errors.first("due date") }}</span
                      >
                    </div>
                    <div class="flex-field">
                      <label class="label is-size-7">
                        Info 'As Of' Date
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>
                      <kendo-datepicker
                        id="asOfDate"
                        name="info date"
                        v-validate="'required'"
                        style="max-width: 150px"
                        v-model="input.asOfDate"
                        :format="'yyyy-MM-dd'"
                      >
                      </kendo-datepicker>
                      <span
                        v-show="errors.has('info date')"
                        class="help is-danger"
                        >{{ errors.first("info date") }}</span
                      >
                    </div>
                  </div>
                  <div class="flex-container" style="margin-top: 1rem">
                    <div class="flex-field">
                      <label for="" class="label">
                        Portal Message
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>

                      <div class="field">
                        <textarea
                          v-model="input.portalMessage"
                          rows="5"
                          cols="50"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column" style="max-width: 50%">
                <div
                  class="block"
                  style="
                    padding: 0px 0px 7px 0px;
                    border-bottom: solid 1px lightgray;
                  "
                >
                  <span class="has-text-weight-bold"
                    >Reminder Schedule</span
                  >
                </div>
                <div
                  v-for="(reminder, i) in notificationsTable"
                  :key="i"
                  class="flex-container flex-left"
                  style="margin-top: 1rem; width: 100%; gap: 0.5rem !important"
                >
                  <div class="flex-field" style="max-width: 150px">
                    <label class="label is-size-7">
                      Reminder Date/Time
                      <span class="has-text-warning" style="padding-left: 3px"
                        >*</span
                      >
                    </label>
                    <kendo-datepicker
                      id="reminderDate"
                      style="width: 100%"
                      v-model="reminder.remindAt"
                      :format="'yyyy-MM-dd'"
                    />
                    <input
                      v-model="reminder.remindAt"
                      v-show="false"
                      :name="`reminder date ${i}`"
                      v-validate="'required'"
                    />
                    <span
                      v-show="errors.has(`reminder date ${i}`)"
                      class="help is-danger"
                      >{{ "The reminder date field is required" }}</span
                    >
                  </div>
                  <div
                    class="flex-field flex-left flex-container"
                    style="
                      width: calc(100% - 0.5rem - 200px) !important;
                      position: relative;
                    "
                  >
                    <div class="flex-field" style="width: 100%">
                      <label class="label is-size-7">
                        Email Template
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>
                      <div class="select" style="width: 100%">
                        <select
                          v-model="reminder.emailTemplateId"
                          :name="`email template ${i}`"
                          v-validate="'required'"
                          style="width: 100%"
                        >
                          <option
                            v-for="opt in emailTemplates.filter(x => QuestionnaireType.id === x.emailTemplateTypeCategoryId)"
                            :key="opt.id"
                            :value="opt.id"
                            :selected="
                              parseInt(opt.id) ===
                              parseInt(reminder.emailTemplateId)
                            "
                          >
                            {{ opt.name }}
                          </option>
                        </select>
                        <span
                          v-show="errors.has(`email template ${i}`)"
                          class="help is-danger"
                          >{{ "The email template field is required" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="flex-field" style="padding: 0; padding-top: .5rem;">
                    <label  class="label is-size-7">
                      &nbsp;
                    </label>
                    <a
                      class="button is-light"
                      v-on:click="removeReminder(reminder)"
                      >-</a
                    >
                  </div>
                </div>
                <div
                  style="width: 100%; display: flex; justify-content: flex-end"
                >
                  <a
                    class="button is-light"
                    style="margin-left: auto; margin-top: 0.5rem"
                    v-on:click="addReminder"
                    >Add reminder</a
                  >
                </div>
                <div
                    class="block"
                    style="
                      padding: 0px 0px 7px 0px;
                      border-bottom: solid 1px lightgray;
                    "
                  >
                    <span class="has-text-weight-bold"
                      >On "Send" Notification</span
                    >
                  </div>
                  <div class="select" style="width: 100%;">
                    <select v-model="input.defaultSentEmailTemplate" style="width: 100%;">
                      <option
                        v-for="opt in emailTemplates.filter(x => QuestionnaireType.id === x.emailTemplateTypeCategoryId)"
                        :key="opt.id"
                        :value="opt.id"
                        :selected="
                          parseInt(opt.id) ===
                          parseInt(input.defaultSentEmailTemplate)
                        "
                      >
                        {{ opt.name }}
                      </option>
                    </select>
                  </div>
              </div>
            </div>
            <div class="columns"  v-if="!isViewOnly">
              <div class="column">
                <div
                  class="block"
                  style="
                    padding: 0px 0px 7px 0px;
                    border-bottom: solid 1px lightgray;
                  "
                >
                  <span class="has-text-weight-bold">Invite Clients</span>
                </div>
                <div class="invite-client-wrapper">
                  <InviteClients
                    :model="this.invitationInput"
                    :selectedClients="this.input.invitations"
                    :clients="this.input.systemClients"
                    :institutionId="this.input.institution.id"
                    @invitationsUpdated="onInvitationsUpdated"
                  />
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div style="margin-top: 20px; text-align: left">
                  <div class="heading" style="font-size: 20px"  v-if="!isViewOnly">
                    {{ name }}
                  </div>
                  <div
                    v-if="false"
                    class="subheading"
                    style="
                      padding: 0px 0px 7px 0px;
                      margin-bottom: 1.5rem;
                      border-bottom: solid 1px lightgray;
                    "
                  >
                    <b>Bank Contact Information</b>
                    <br />
                    Please review and correct the information as needed.
                  </div>
                  <div class="columns" v-if="false">
                    <div
                      class="column flex-container flex-full"
                      style="
                        flex-wrap: wrap;
                        gap: 0.5rem !important;
                        padding: 0.5rem !important;
                      "
                    >
                      <div class="field" style="width: 100%">
                        <label class="label text-left is-size-7">
                          Legal Address
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientAddress"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(50% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          City
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientAddressCity"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(15% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          ST
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientAddressState"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(34% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          Postal Code
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientAddressPostal"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="column flex-container flex-full"
                      style="
                        flex-wrap: wrap;
                        gap: 0.5rem !important;
                        padding: 0.5rem !important;
                      "
                    >
                      <div class="field" style="width: 100%">
                        <label class="label text-left is-size-7">
                          Mailing Address
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientMailingAddress"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(50% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          City
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientMailingAddressCity"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(15% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          ST
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientMailingAddressState"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="max-width: calc(34% - 0.25rem) !important"
                      >
                        <label class="label text-left is-size-7">
                          Postal Code
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center" style="width: 100%">
                          <input
                            style="width: 100%"
                            v-model="input.clientMailingAddressPostal"
                            name="legal address"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('legal address')"
                            class="help is-danger"
                            >{{ errors.first("legal address") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="column flex-container flex-full"
                      style="
                        flex-wrap: wrap;
                        gap: 0.5rem !important;
                        padding: 0.5rem !important;
                      "
                    >
                      <div
                        class="field"
                        style="padding: 0 !important; margin: 0px !important"
                      >
                        <label class="label text-left is-size-7">
                          Main Phone
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center">
                          <input
                            v-model="input.clientPhone"
                            @keydown="forceFormat"
                            @keyup="formatPhoneInput"
                            name="business phone"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('business phone')"
                            class="help is-danger"
                            >{{ errors.first("business phone") }}</span
                          >
                        </div>
                      </div>
                      <div class="field">
                        <label class="label text-left is-size-7">
                          Main Fax
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="control-center">
                          <input
                            v-model="input.clientFax"
                            @keydown="forceFormat"
                            @keyup="formatPhoneInput"
                            name="fax number"
                            v-validate="'required'"
                          />
                          <span
                            v-show="errors.has('fax number')"
                            class="help is-danger"
                            >{{ errors.first("fax number") }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="false"
                    class="subheading"
                    style="
                      padding: 0px 0px 7px 0px;
                      margin-bottom: 0.5rem;
                      border-bottom: solid 1px lightgray;
                      position: relative;
                    "
                  >
                    <b>Contact Information</b>
                    <div class="show-deleted">
                      <button
                        v-if="hasDeletedContacts"
                        @click="showDeletedContacts = !showDeletedContacts"
                        type="button"
                      >
                        {{ showDeletedContacts ? "Hide" : "Show" }} Deleted
                        Contacts
                      </button>
                      <button
                        v-if="!isViewOnly"
                        @click="resetContactTable()"
                        class="button is-warning is-small"
                        type="button"
                        style="margin-left: 1rem"
                      >
                        Reset Contacts
                      </button>
                    </div>
                    <br />
                    Please review and adjust entries as needed.
                  </div>
                  <div class="table" style="min-width: 100%;" v-if="false">
                    <DataTable
                      :header-fields="contactFields"
                      :tableData="filteredContacts"
                      :is-loading="isLoading"
                      :css="datatableCss"
                      :disableAddRow="isViewOnly"
                      :limit="100"
                      not-found-msg="No contacts found"
                      @on-add-row="addRow(contactTable, 'contactTable')"
                      style="min-width: 100%;"
                    >
                      <div v-if="!isViewOnly" slot="actions" slot-scope="props">
                        <a
                          v-if="
                            props &&
                            props.rowData &&
                            props.rowData.isDeleted === 0
                          "
                          class="button is-light"
                          @click="
                            props.rowData.contactId &&
                            props.rowData.contactId != 0
                              ? (props.rowData.isDeleted = 1)
                              : removeRow(contactTable, props, 'contactTable')
                          "
                          style="max-width: 50px"
                          >-</a
                        >
                        <a
                          v-if="
                            props &&
                            props.rowData &&
                            props.rowData.isDeleted === 1
                          "
                          class="button is-light"
                          @click="props.rowData.isDeleted = 0"
                          style="max-width: 50px"
                          >+</a
                        >
                      </div>
                    </DataTable>
                    <br />
                  </div>
                  <div
                    class="subheading"
                    style="
                      padding: 0px 0px 7px 0px;
                      margin-bottom: 0.5rem;
                      border-bottom: solid 1px lightgray;
                      position: relative;
                    "
                  >
                    <b>Participant Basic Information</b>
                    <br />
                    Please review and adjust entries as needed.
                    <div class="show-deleted">
                      <button
                        v-if="hasDeletedParticipants"
                        @click="
                          showDeletedParticipants = !showDeletedParticipants
                        "
                        type="button"
                      >
                        {{ showDeletedParticipants ? "Hide" : "Show" }} Deleted
                        Participants
                      </button>
                      <button
                        v-if="!isViewOnly"
                        @click="resetParticipantsTable()"
                        class="button is-warning is-small"
                        type="button"
                        style="margin-left: 1rem"
                      >
                        Reset Participants
                      </button>
                    </div>
                  </div>
                  <div style="min-width: 100%;">
                    <DataTable
                      :header-fields="participantFields"
                      :tableData="filteredParticipants"
                      :is-loading="isLoading"
                      :css="datatableCss"
                      :disableAddRow="isViewOnly"
                      :limit="100"
                      not-found-msg="No participants found"
                      @on-add-row="addRow(participantTable, 'participantTable')"
                      style="min-width: 100%;"
                    >
                      <div v-if="!isViewOnly" slot="actions" slot-scope="props">
                        <a
                          v-if="
                            props &&
                            props.rowData &&
                            props.rowData.isDeleted === 0
                          "
                          class="button is-light"
                          @click="
                            props.rowData.participantId &&
                            props.rowData.participantId != 0
                              ? (props.rowData.isDeleted = 1)
                              : removeRow(
                                  participantTable,
                                  props,
                                  'participantTable'
                                )
                          "
                          style="max-width: 50px"
                          >-</a
                        >
                        <a
                          v-if="
                            props &&
                            props.rowData &&
                            props.rowData.isDeleted === 1
                          "
                          class="button is-light"
                          @click="props.rowData.isDeleted = 0"
                          style="max-width: 50px"
                          >+</a
                        >
                      </div>
                    </DataTable>
                  </div>
                </div>

                <div
                  id="plan-type-modal"
                  class="modal is-active"
                  v-if="showPlanTypeModal"
                >
                  <div
                    class="modal-background"
                    @click="showPlanTypeModal = false"
                  ></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <div class="modal-card-title">Add New Plan Type</div>
                      <a
                        class="delete"
                        aria-label="close"
                        @click="showPlanTypeModal = false"
                      ></a>
                    </header>
                    <section class="modal-card-body">
                      <div class="content" v-if="planTypeExists">
                        <b
                          >Plan Type '{{ newPlanType.toUpperCase() }}' already
                          exists.</b
                        >
                        Please select it from the list or enter a new Plan Type.
                      </div>
                      <div class="field">
                        <div class="control">
                          <input
                            type="text"
                            class="input"
                            v-model="newPlanType"
                            placeholder="Enter a new Plan Type Code"
                            v-focus
                          />
                        </div>
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <a
                        class="button is-accent has-text-white"
                        @click="addPlanType"
                      >
                        <span>Save Plan Type</span>
                      </a>
                      <a class="button" @click="showPlanTypeModal = false"
                        >Cancel</a
                      >
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import DataTable from '../templates/DataTable'
import Loading from '../Loading'
import InviteClients from '../EditQuestionnaireClients'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'

let pageName = ''

export default {
  components: {
    Loading,
    DataTable,
    InviteClients,
    'kendo-datepicker': DatePicker
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          year: '',
          institution: '',
          planType: '',
          contactFields: [
            {
              name: 'name',
              label: 'Name',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'title',
              label: 'Title',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'phone',
              label: 'Direct Phone',
              format: 'phone',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'email',
              label: 'Email',
              width: '25%!important',
              isEditable: true
            },
            '__slot:actions'
          ],
          participantFields: [
            {
              name: 'firstName',
              label: 'First Name',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'lastName',
              label: 'Last Name',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'previousTitle',
              label: 'Previous Title',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'newTitle',
              label: 'New Title',
              width: '25%!important',
              isEditable: true
            },
            {
              name: 'terminationDate',
              label: 'Termination Date',
              format: 'date',
              isEditable: true
            },
            {
              name: 'terminationReason',
              label: 'Termination Reason',
              isEditable: true
            },
            {
              name: 'salary',
              label: 'Salary',
              isEditable: true
            },
            {
              name: 'bonus',
              label: 'Bonus',
              isEditable: true
            },
            {
              name: 'otherCompensation',
              label: 'Other Compensation',
              isEditable: true
            },
            '__slot:actions'
          ],
          contactTable: [{}],
          datatableCss: {
            table: 'table table-bordered table-hover table-striped table-center',
            th: 'header-item',
            thWrapper: 'th-wrapper',
            footer: 'footer'
          }
        }
      }
    }
  },
  data () {
    return {
      inputBackup: {},
      templateTypeCategories: [],
      showDeletedParticipants: false,
      showDeletedContacts: false,
      input: {
        id: 0,
        year: '',
        institution: '',
        planType: '',
        dueDate: '',
        asOfDate: ''
      },
      id: 0,
      year: '',
      years: [],
      name: '',
      disableName: true,
      institution: {},
      institutions: [],
      planType: {},
      planTypes: [],
      emailTemplate: {},
      emailTemplates: [],
      isLoading: false,
      showPlanTypeModal: false,
      showForm: false,
      newPlanType: '',
      planTypeExists: false,
      dueDate: null,
      asOfDate: null,
      legalAddress: '',
      mailingAddress: '',
      isViewOnly: false,
      phone: '',
      fax: '',
      contactFields: [
        {
          name: 'name',
          label: 'Name',
          width: '25%!important',
          isEditable: true
        },
        {
          name: 'title',
          label: 'Title',
          width: '25%!important',
          isEditable: true
        },
        {
          name: 'phone',
          label: 'Direct Phone',
          format: 'phone',
          width: '25%!important',
          isEditable: true
        },
        {
          name: 'email',
          label: 'Email',
          width: '25%!important',
          isEditable: true
        },
        '__slot:actions'
      ],
      contactTable: [{}],
      participantFields: [
        {
          name: 'firstName',
          label: 'First Name',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'previousTitle',
          label: 'Previous Title',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'newTitle',
          label: 'New Title',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'terminationDate',
          label: 'Termination Date',
          format: 'date',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'terminationReason',
          label: 'Termination Reason',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'salary',
          label: 'Salary',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'bonus',
          label: 'Bonus',
          width: '11.1111111111%',
          isEditable: true
        },
        {
          name: 'otherCompensation',
          label: 'Other Compensation',
          width: '11.1111111111%',
          isEditable: true
        },
        '__slot:actions'
      ],
      participantTable: [{}],
      datatableCss: {
        table: 'table table-bordered table-hover table-striped table-center',
        th: 'header-item',
        thWrapper: 'th-wrapper',
        footer: 'footer'
      },
      invitationsInput: {
        users: []
      },
      notificationsTable: [
        {
          id: 0,
          reminderDate: '',
          emailTemplate: ''
        }
      ]
    }
  },
  watch: {
    input: {
      handler () {
        this.checkToDisable()
        if (this.input.year !== '' && this.input.institution !== '') {
          this.name = this.input.name
          this.disableName = false
          this.showForm = true
        }
      },
      deep: true
    },
    participantTable: {
      handler () {
        this.checkToDisable()
      },
      deep: true
    },
    contactTable: {
      handler () {
        this.checkToDisable()
      },
      deep: true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageheading () {
      return !this.isViewOnly ? 'Annual Questionnaire - @questionnaireName'.replace('@questionnaireName', this.name) : 'Annual Questionnaire Response - @questionnaireName'.replace('@questionnaireName', this.name)
    },
    filteredParticipants () {
      return this.participantTable && this.participantTable.length > 0
        ? this.showDeletedParticipants
          ? this.participantTable
          : this.participantTable.filter((c) => !c.isDeleted)
        : []
    },
    filteredContacts () {
      return this.contactTable && this.contactTable.length > 0
        ? this.showDeletedContacts
          ? this.contactTable
          : this.contactTable.filter((c) => !c.isDeleted)
        : []
    },
    QuestionnaireType () {
      if (this.templateTypeCategories && this.templateTypeCategories.length > 0) {
        return this.templateTypeCategories.find(x => x.description === 'Reporting Questionnaire')
      } else {
        return {}
      }
    },
    hasDeletedContacts () {
      return this.contactTable && this.contactTable.length > 0
        ? this.contactTable.filter((c) => c.isDeleted).length > 0
        : false
    },
    hasDeletedParticipants () {
      return this.participantTable && this.participantTable.length > 0
        ? this.participantTable.filter((c) => c.isDeleted).length > 0
        : false
    },
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      let isCreateMode = !id || id <= 0

      // if (!isCreateMode) {
      //   pageName = 'Edit Questionnaire'
      // }
      return isCreateMode
    }
  },
  async beforeRouteEnter (to, from, next) {
    next(async (vm) => {
      if (!vm.isCreateMode) {
        if (to.query && to.query.isViewOnly !== undefined && to.query.isViewOnly === true) {
          vm.isViewOnly = true
        } else {
          vm.isViewOnly = false
        }

        await vm.api().annualQuestionnaire.getAssetReportInBatchDetail(
          {
            batchId: to.params.id,
            reportId: to.params.reportId
          },
          (error, model) => {
            if (error) {
              vm.handleApiErr(error)
              return
            }

            if (model) {
              vm.input = { ...model }
              vm.input.id = model.id
              vm.input.year = model.year
              vm.input.institution = model.clientId
              vm.input.planType = model.planType
              vm.input.stickyNote = model.stickyNote
              vm.input.dueDate = model.dueDate
              vm.input.asOfDate = model.asOfDate
              vm.name = model.name

              vm.contactTable = model.contacts
              vm.participantTable = model.participants
              vm.notificationsTable = model.notificationSchedules
              vm.invitationInput = {
                users: model.invitations.map((i) => i.clientId)
              }

              vm.inputBackup = JSON.parse(JSON.stringify(vm.input))

              vm.phone = model.phone
              vm.fax = model.fax
            }
          }
        )
      }
    })
  },
  async created () {
    await this.getYears()
    // await this.getInstitutions()
    // await this.getPlanTypes()
    await this.getEmailTemplates()
    await this.getEmailTemplateCategories()
    // if external direct link, need to load the model stateCode
    if (!this.isCreateMode && (!this.model || this.model.id <= 0)) {
      this.showForm = true
      // await this.loadModel('/questionnaires/', null, false)
      // TODO: Parse JSON into objects to populate
    } else {
      await this.setupForm(this.model, false)
    }
  },
  mounted () {
    this.checkToDisable()
  },
  methods: {
    async getEmailTemplateCategories () {
      try {
        const route = `/EmailTemplates/TemplateTypeCategories`

        let response = await this.axios.get(route)
        this.templateTypeCategories = response.data
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    checkToDisable () {
      if (this.isViewOnly) {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            document.querySelectorAll('input')
              .forEach(input => input.setAttribute('disabled', true) && input.addEventListener('click', _ => _.stopPropagation()))
            document.querySelectorAll('.k-widget .k-select')
              .forEach(input => input.remove())
          })
        }, 100)
      }
    },
    addRow (table, tableKey) {
      let row = {
        id: 0,
        name: '',
        title: '',
        phone: '',
        email: '',
        isDeleted: 0
      }
      this[tableKey].push(row)
    },
    resetContactTable () {
      window.requestAnimationFrame(() => {
        this.contactTable = JSON.parse(
          JSON.stringify(this.inputBackup.contacts)
        )
      })
    },
    resetParticipantsTable () {
      window.requestAnimationFrame(() => {
        this.participantTable = JSON.parse(
          JSON.stringify(this.inputBackup.participants)
        )
      })
    },
    async getYears () {
      try {
        let minYear = 2021 // 2021 is the first year when Annual Questionnaires were implemented
        const maxYear = new Date().getFullYear()
        for (; minYear <= maxYear; minYear++) {
          this.years.push(minYear)
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutions () {
      try {
        let route = '/institutions/all/'
        let response = await this.axios.get(route)
        this.institutions = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPlanTypes () {
      try {
        let route = '/questionnaires/plan-types/'
        let response = await this.axios.get(route)
        this.planTypes = response.data
        this.input.planTypes = this.planTypes
        this.planType = this.planTypes[0]
        // TODO: Uncomment for Phase 2 - backend already complete
        // this.planTypes.push({ 'id': -1, 'typeCode': 'Add a new Plan Type' })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getEmailTemplates () {
      try {
        let route = '/email-templates/'
        let response = await this.axios.get(route)
        this.emailTemplates = response.data.records.filter(
          (r) => r.emailTemplateTypeId === 2
        )
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    checkPlanType () {
      if (this.planType === -1) {
        this.showPlanTypeModal = true
        this.planTypeExists = false
        this.newPlanType = ''
      }
    },
    async addPlanType (event, data) {
      this.planTypes.forEach((x) => {
        if (x.typeCode === this.newPlanType.toUpperCase()) {
          this.planTypeExists = true
        }
      })

      if (!this.planTypeExists) {
        try {
          let route = '/questionnaires/plan-types/'
          await this.axios.post(route, JSON.stringify(this.newPlanType), {
            headers: { 'Content-Type': 'application/json' }
          })

          this.getPlanTypes()

          this.successToast(
            `Successfully created Plan Type ${this.newPlanType}!`
          )
          this.showPlanTypeModal = false
        } catch (error) {
          this.handleApiErr(error)
        }
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((bool) => {
        if (bool) {
          this.handleSubmitLocal(
            '/questionnaire/',
            -1,
            false,
            false,
            `The Questionnaire ID '${this.id}' already exists. Please enter a unique ID.`
          )
        }

        // scroll to first validation error
        let errors = document.querySelectorAll('.help')
        const firstError = [...errors].find((el) => el.style.display === '')

        if (firstError) {
          firstError.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    },
    async handleSubmitLocal (route, nav) {
      let responseData = {
        ...this.input,
        contacts: this.contactTable,
        participants: this.participantTable,
        notificationSchedules: this.notificationsTable,
        invitations:
          this.invitationInput && this.invitationInput.users.length > 0
            ? this.invitationInput.users.map((x) => {
              return {
                invitationId: 0,
                clientId: x,
                questionnaireId: this.$route.params.reportId
              }
            })
            : []
      }

      // this.input.responseData = JSON.stringify(responseData)
      // this.input.name = this.name
      // this.input.planTypes = [this.input.planTypes[0].id] /// TODO: Map to array ids once multiselect enabled
      // this.input.schedules = [...this.notificationsTable]
      // this.input.invitations = this.InviteClients

      this.axios
        .patch(
          `annual-questionnaire/${this.$route.params.id}/reports/${this.$route.params.reportId}`,
          responseData
        )
        .then((response) => {
          if (response.status === 200) {
            this.successToast('Successfully saved Questionnaire!')
            this.$router.go(-1)
          }
        })
        .catch((error) => {
          this.handleApiErr(error)
        })
        .finally(() => {
          this.isLoading = false
        })

      // await this.handleSubmit(route, -1)
    },
    addReminder () {
      const newReminder = {
        id: this.notificationsTable.length,
        reminderDate: '',
        emailTemplate: ''
      }
      this.notificationsTable.push(newReminder)
    },
    removeReminder (data) {
      if (this.notificationsTable.length > 1) {
        this.notificationsTable = this.notificationsTable.filter(
          (row) => row !== data
        )
      }
    },
    removeRow: function (table, row, sourceTable) {
      if (table.length > 1) {
        table.splice(row.rowIndex, 1)
      } else {
        this[sourceTable] = [{}]
      }
    },
    onInvitationsUpdated (event) {
      this.invitationInput = event
    },
    forceFormat (event) {
      // check if input value is a number value
      let isNumericInput
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        isNumericInput = true
      } else {
        isNumericInput = false
      }

      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // if inputed value is not a modifier or numeric strip it
      if (!isNumericInput && !isModifierKey) {
        event.preventDefault()
      }
    },
    formatPhoneInput (event) {
      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if (
        event.shiftKey === true ||
        event.keyCode === 35 ||
        event.keyCode === 36 ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 13 ||
        event.keyCode === 46 ||
        (event.keyCode > 36 && event.keyCode < 41) ||
        ((event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 65 ||
            event.keyCode === 67 ||
            event.keyCode === 86 ||
            event.keyCode === 88 ||
            event.keyCode === 90))
      ) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // dont change anything if modifier key inputed
      // eslint-disable-next-line block-spacing
      if (isModifierKey) {
        return
      }

      // get the input element and auto format value to (000) 000-0000
      const el = event.target
      const userInput = el.value.replace(/\D/g, '').substring(0, 10)
      const zip = userInput.substring(0, 3)
      const middle = userInput.substring(3, 6)
      const last = userInput.substring(6, 10)

      if (userInput.length > 6) {
        el.value = `(${zip}) ${middle}-${last}`
      } else if (userInput.length > 3) {
        el.value = `(${zip}) ${middle}`
      } else if (userInput.length > 0) {
        el.value = `(${zip}`
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.subheading {
  font-size: 1em;
  padding: 30px 0 10px 0;
}

div.flex-field {
  display: inline-block;
}

div.flex-field:not(:first-child) {
  padding-left: 20px;
}

.field {
  color: #4a4a4a;
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 0.5rem;
}

.text-left {
  text-align: left !important;
}

.flex-container .flex-field {
  padding: 0px !important;
  margin: 0px !important;
}

.flex-center {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-left {
  justify-content: flex-start !important;
}

.flex-full {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-full div {
  width: 100%;
}

.flex-full div div {
  width: 100%;
}

.flex-full div div input {
  width: 100%;
}

.field input,
.field textarea,
.flex-field input,
.column input,
.column textarea {
  font-size: 1em;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  min-height: 36px;
  color: #363636;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
}

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding-right: 2.8rem; */
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}

.annual-questionnaire-form input[disabled=true] {
  opacity: .75!important;
  background: #f0f0f0;
}
/* End Invite Client CSS */
</style>

<style scoped>
.column {
  padding: 0.5rem !important;
  margin: 0rem !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}

</style>
